import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";
import UIkit from "uikit";
import { _tmpCalendarMonth } from "../datas/data";
import { _hensuu } from "../datas/data";

export default createStore({
  // plugins: [createPersistedState()],
  modules: {},
  state: {
    // st_dummy: "", // dummyとしてconsole出力する用
    st_headerHospitalName: "医療法人医誠会", // 仮に表示する用
    st_headerSystemName: "面会WEB予約",
    st_isServerProcessing: false, // サーバー処理中判定用
    st_isDbConnect: true, // DB接続判定用
    st_isCatchError: false, // エラー表示用

    // 画面操作に必要な情報
    st_facilityInfo: {}, // 施設情報
    st_consentInfo: {}, // 注意事項情報
    st_calendardayInfo: {}, // カレンダー情報
    st_timezoneInfo: {}, // 時間帯情報
    st_reserveCountInfo: {}, // 予約数情報
    st_floorInfo: {}, // 階情報
    st_hproomInfo: {}, // 病室情報
    st_relationInfo: {}, // 患者さまとのご関係情報
    st_relationDetailInfo: {}, // 患者さまとのご関係詳細情報
    st_visitorLimit: 0, // 訪問人数制限

    // 主にSchedulePageで使用
    st_monthItem: [], // 年月配列
    st_calendarItem: [], // カレンダーの内容
    st_timezoneItem: [], // 画面に表示する時間帯リスト
    st_modalId_timezone: "id_modal_timezone", // 時間帯選択モーダルのID

    // 主にInputInfoPageで使用
    st_dispFloorAndRoomCompArea: false, // 階、病室選択エリア表示制御
    st_dispFloorSelectionComp: false, // 階選択コンポーネント表示制御
    st_dispRoomSelectionComp: false, // 病室選択コンポーネント表示制御
    st_tmpByoutouList: [], // 選択した階で絞った病室リスト
    st_dispVisitorCompArea: false, // 訪問者情報入力エリア表示制御
    st_dispVisitorCountComp: false, // 訪問者人数選択コンポーネント表示制御
    st_dispVisitorDetailComp: false, // 訪問者情報入力コンポーネント表示制御
    st_flgDuringValidate: false, // 入力情報validate検証中
    st_flgDuringValidateVisitorInfo: false, // 訪問者情報validate検証中
    st_dispInputedVisitorlInfoArea: false, // 入力を確定した訪問者情報の表示制御
    st_dispIncorrectAlertContact: false, // 電話番号不正警告表示制御
    st_dispIncorrectAlertMailaddress: false, // メールアドレス不正警告表示制御

    // 主にMyReservationPageで使用
    st_dispInputReservationNumberArea: false, // 予約番号入力エリア表示制御
    st_dispReservationInfoComp: false, // 予約内容表示コンポーネント表示制御
    st_dispReservationEditIcon: false, // 変更アイコン表示制御
    st_dispCalendarArea: false, // カレンダーコンポーネント表示制御
    st_isDirectlyTimezoneModal: false, // 時間帯モーダル直接開く判定
    st_dispPatientNameEditArea: false, // 患者氏名編集エリア表示制御
    st_modalId_reservation_edit: "id_modal_reservation_edit", // 予約変更確認モーダルのID
    st_modalId_reservation_delete: "id_modal_reservation_delete", // 予約取消確認モーダルのID
    st_isEditableFlg: false, // 予約変更可能判定用
    st_isVisitedFlg: false, // 来訪済み判定用
    // 予約情報の変更有無を保持
    // コードの中で数値で扱うとややこしくなるため変数で定義しておく
    st_editingInfo: {
      reserve_ymd: { num: 1, edited: false },
      timezone: { num: 2, edited: false },
      patient_name: { num: 3, edited: false },
      floor: { num: 4, edited: false },
      room: { num: 5, edited: false },
      visitor_name: { num: 6, edited: false },
      visitor_tel: { num: 7, edited: false },
      visitor_mail: { num: 8, edited: false },
      visitor_relation_detail_name: { num: 9, edited: false },
    },

    // 主にConfirmInputPageで使用
    st_dispConfirmInputArea: false, // 入力内容確認表示制御
    st_dispConfirmSendMailArea: false, // メール送信確認画面表示制御
    // st_dispConfirmReSendMailArea: false, // メール再送信確認画面表示制御
    st_reservationInfo: {}, // 予約情報

    // 主にFinishReservationPageで使用
    st_dispFinishReservationSurface: false, // 予約完了画面表示制御
    st_dispReservationNoneSurface: false, // 予約情報なし画面表示制御
    st_dispReservationDoneSurface: false, // 予約確定済み画面表示制御
    st_dispDuplicateReservationSurface: false, // 予約重複画面表示制御
    st_dispUrlParametersOnlyIdSurface: false, // URLパラメータで予約IDのみ取得できた場合の画面表示制御

    // 主にCheckInPageで使用
    st_dispCheckInSurface: false, // 面会開始画面表示制御
    st_dispNotLatestReservationSurface: false, // 予約情報変更済み画面表示制御
    st_dispCheckInDoneSurface: false, // チェックイン済み画面表示制御
    st_dispReceptionDoneSurface: false, // 受付済み画面表示制御
    st_dispYetMenkaiStartSurface: false, // 面会時間未達画面表示制御
    st_dispFinishMenkaiEndSurface: false, // 面会時間終了画面表示制御
    st_dispReceptionSurface: false, // 受付開始画面表示制御
    st_checkHealthFlg: false, // 健康観察確認判定用
    st_checkLuggageFlg: false, // お荷物について確認判定用

    // 主にNextReservationPageで使用
    st_isInputNextReservationFlg: false, // 次回予約情報登録判定用
    st_modalId_next_reservation: "id_modal_next_reservation", // 次回予約登録確認モーダルのID

    // 画面で入力した内容保持用
    st_inputInfo: {
      facility_code: "",
      url: "",
      year: "",
      month: "",
      date: "",
      timezone: "",
      timezone_disp: "",
      patient_name: "",
      floor: "",
      floor_text: "",
      room: "",
      room_text: "",
      visitor_count: 1,
      visitor: [],
      reservation_id: "",
      reservation_number: "",
      reservation_tel: "",
      reservation_serial: "",
    },

    // 予約編集用（編集中の値一時保持用）
    st_editTemporaryInfo: {
      facility_code: "",
      url: "",
      year: "",
      month: "",
      date: "",
      timezone: "",
      timezone_disp: "",
      patient_name: "",
      floor: "",
      floor_text: "",
      room: "",
      room_text: "",
      visitor_count: 1,
      visitor: [],
      reservation_id: "",
      reservation_number: "",
      reservation_tel: "",
      reservation_serial: "",
    },

    // 予約編集用（編集前の値）
    st_preEditInfo: {
      facility_code: "",
      url: "",
      year: "",
      month: "",
      date: "",
      timezone: "",
      timezone_disp: "",
      patient_name: "",
      floor: "",
      floor_text: "",
      room: "",
      room_text: "",
      visitor_count: 1,
      visitor: [],
      reservation_id: "",
      reservation_number: "",
      reservation_tel: "",
      reservation_serial: "",
    },
  },
  getters: {},
  mutations: {
    // サーバー処理中の状態管理
    mut_setIsServerProcess: (state, bln) => {
      state.st_isServerProcessing = bln;
    },
    // 処理終了通知を表示する
    mut_notificationEndProc: (state, bln) => {
      /* eslint-disable no-unused-vars */
      state;
      /* eslint-enable no-unused-vars */
      // 一旦処理成功時の値をセットする
      let _status = "success";
      let _timeout = 3000;
      let _msg =
        "<div" +
        " class='uk-flex uk-flex-center uk-flex-middle uk-text-success uk-padding-small common-font'" +
        " style='background-color:honeydew; border:1px solid teal; border-radius:20px;'>" +
        "<span uk-icon='happy'></span>" +
        "<span>処理が完了しました</span>" +
        "</div>";
      // エラーの場合はエラー用の値をセットする
      // 違いはテキスト色、背景色、ボーダー色、アイコン
      if (!bln) {
        _status = "danger";
        _timeout = 0;
        _msg =
          "<div" +
          " class='uk-flex uk-flex-center uk-flex-middle uk-text-danger uk-padding-small common-font'" +
          " style='background-color:mistyrose; border:1px solid orangered; border-radius:20px;'>" +
          "<span uk-icon='warning'></span>" +
          "<span>処理に失敗しました</span>" +
          "</div>";
      }
      // 処理中クルクルの上から表示するためのz-index指定
      UIkit.notification({
        message: _msg,
        status: _status,
        pos: "top-right",
        timeout: _timeout,
        "z-index": "1150",
      });
    },
    // 任意のメッセージを通知する
    mut_notificationAny: (state, pVal) => {
      /* eslint-disable no-unused-vars */
      state;
      /* eslint-enable no-unused-vars */
      // 一旦処理成功時の値をセットする
      let _status = "success";
      let _timeout = 3000;
      let _msg =
        "<div" +
        " class='uk-flex uk-flex-center uk-flex-middle uk-text-success uk-padding-small common-font'" +
        " style='background-color:honeydew; border:1px solid teal; border-radius:20px;'>" +
        "<span uk-icon='check'></span>" +
        "<span>" +
        pVal.msg +
        "</span>" +
        "</div>";

      // エラーの場合
      // 違いはテキスト色、背景色、ボーダー色、アイコン
      if (!pVal.flg) {
        _status = "warning";
        _timeout = 0;
        _msg =
          "<div" +
          " class='uk-flex uk-flex-center uk-flex-middle uk-text-warning uk-padding-small common-font'" +
          " style='background-color:#fdf5e6; border:1px solid orange; border-radius:20px;'>" +
          "<span uk-icon='warning'></span>" +
          "<span>" +
          pVal.msg +
          "</span>" +
          "</div>";
      }
      // 処理中クルクルの上から表示するためのz-index指定
      UIkit.notification({
        message: _msg,
        status: _status,
        pos: "top-right",
        timeout: _timeout,
        "z-index": "1150",
      });
    },
    // 通知を全て閉じる
    mut_closeAllNotification: (state) => {
      /* eslint-disable no-unused-vars */
      state;
      /* eslint-enable no-unused-vars */
      UIkit.notification.closeAll();
    },
    // 施設コード保持
    mut_holdFacilityCode: (state, val) => {
      state.st_facilityCode = val;
    },
    // 施設情報保持
    mut_holdFacilityInfo: (state, val) => {
      state.st_facilityInfo = val;
    },
    // 注意事項保持
    mut_holdConsentInfo: (state, val) => {
      state.st_consentInfo = val;
    },
    // カレンダー情報保持
    mut_holdCalendardayInfo: (state, val) => {
      state.st_calendardayInfo = val;
    },
    // 時間帯情報保持
    mut_holdTimezoneInfo: (state, val) => {
      state.st_timezoneInfo = val;
    },
    // 予約数情報保持
    mut_holdReserveCountInfo: (state, val) => {
      state.st_reserveCountInfo = val;
    },
    // 階情報保持
    mut_holdFloorInfo: (state, val) => {
      state.st_floorInfo = val;
    },
    // 病室情報保持
    mut_holdHproomInfo: (state, val) => {
      state.st_hproomInfo = val;
    },
    // 訪問人数上限保持
    mut_holdVisitorLimit: (state, val) => {
      state.st_visitorLimit = val;
    },
    // 患者さまとのご関係情報保持
    mut_holdRelationInfo: (state, val) => {
      state.st_relationInfo = val;
    },
    // 患者さまとのご関係詳細情報保持
    mut_holdRelationDetailInfo: (state, val) => {
      state.st_relationDetailInfo = val;
    },
    // 予約情報保持
    mut_holdReservationInfo: (state, val) => {
      state.st_reservationInfo = val;
    },
    // 画面ヘッダーの施設名をセットする
    mut_setDispHeaderFacilityName: (state, val) => {
      state.st_headerHospitalName = val;
    },
    // 入力中の情報をクリア
    mut_clearInputInfo: (state) => {
      let _items = state.st_inputInfo;
      _items.facility_code = "";
      _items.url = "";
      _items.year = "";
      _items.month = "";
      _items.date = "";
      _items.timezone = "";
      _items.timezone_disp = "";
      _items.patient_name = "";
      _items.floor = "";
      _items.floor_text = "";
      _items.room = "";
      _items.room_text = "";
      _items.visitor_count = 1;
      _items.visitor = [];
      _items.reservation_id = "";
      _items.reservation_number = "";
      _items.reservation_tel = "";
      _items.reservation_id = "";
      _items.reservation_number = "";
      _items.reservation_tel = "";
      _items.reservation_serial = "";

      state.st_monthItem = []; // 年月配列
      state.st_calendarItem = []; // カレンダーの内容
      state.st_timezoneItem = []; // 画面に表示する時間帯リスト

      // 変更あり判定をリセットする
      state.st_editingInfo.reserve_ymd.edited = false;
      state.st_editingInfo.timezone.edited = false;
      state.st_editingInfo.patient_name.edited = false;
      state.st_editingInfo.floor.edited = false;
      state.st_editingInfo.room.edited = false;
      state.st_editingInfo.visitor_name.edited = false;
      state.st_editingInfo.visitor_tel.edited = false;
      state.st_editingInfo.visitor_mail.edited = false;
      state.st_editingInfo.visitor_relation_detail_name.edited = false;
    },
    // 子画面等の表示をオフ
    mut_dispNoneChildComponent: (state) => {
      state.st_dispFloorAndRoomCompArea = false; // 階、病室選択エリア表示制御
      state.st_dispFloorSelectionComp = false; // 階選択コンポーネント表示制御
      state.st_dispRoomSelectionComp = false; // 病室選択コンポーネント表示制御
      state.st_dispVisitorCompArea = false; // 訪問者情報入力エリア表示制御
      state.st_dispVisitorCountComp = false; // 訪問者人数選択コンポーネント表示制御
      state.st_dispVisitorDetailComp = false; // 訪問者情報入力コンポーネント表示制御
      state.st_flgDuringValidate = false; // 入力情報validate検証中
      state.st_flgDuringValidateVisitorInfo = false; // 訪問者情報validate検証中
      state.st_dispInputedVisitorlInfoArea = false; // 入力を確定した訪問者情報の表示制御
      state.st_dispIncorrectAlertContact = false; // 電話番号不正警告表示制御
      state.st_dispIncorrectAlertMailaddress = false; // メールアドレス不正警告表示制御

      state.st_dispInputReservationNumberArea = false; // // 予約番号入力エリア表示制御
      state.st_dispReservationInfoComp = false; // 予約内容表示コンポーネント表示制御
      state.st_dispReservationEditIcon = false; // 変更アイコン表示制御
      state.st_dispCalendarArea = false; // カレンダーエリア表示制御
      state.st_isDirectlyTimezoneModal = false; // 時間帯モーダル直接開く判定
      state.st_dispPatientNameEditArea = false; // 患者氏名編集エリア表示制御
      state.st_isEditableFlg = false; // 予約変更可能判定用
      state.st_isVisitedFlg = false; // 来訪済み判定用

      state.st_dispConfirmInputArea = false; // 入力内容確認表示制御
      state.st_dispConfirmSendMailArea = false; // メール送信確認画面表示制御
      // state.st_dispConfirmReSendMailArea = false; // メール再送信確認画面表示制御

      state.st_dispFinishReservationSurface = false; // 予約完了画面表示制御
      state.st_dispReservationNoneSurface = false; // 予約情報なし画面表示制御
      state.st_dispReservationDoneSurface = false; // 予約確定済み画面表示制御
      state.st_dispDuplicateReservationSurface = false; // 予約重複画面表示制御
      state.st_dispUrlParametersOnlyIdSurface = false; // URLパラメータで予約IDのみ取得できた場合の画面表示制御

      state.st_dispCheckInSurface = false; // 面会開始画面表示制御
      state.st_dispNotLatestReservationSurface = false; // 予約情報変更済み画面表示制御
      state.st_dispCheckInDoneSurface = false; // チェックイン済み画面表示制御
      state.st_dispReceptionDoneSurface = false; // 受付済み画面表示制御
      state.st_dispYetMenkaiStartSurface = false; // 面会時間未達画面表示制御
      state.st_dispFinishMenkaiEndSurface = false; // 面会時間終了画面表示制御
      state.st_dispReceptionSurface = false; // 受付開始画面表示制御
      state.st_checkHealthFlg = false; // 健康観察確認判定用
      state.st_checkLuggageFlg = false; // お荷物について確認判定用

      state.st_isInputNextReservationFlg = false; // 次回予約情報登録判定用
    },
    // 予約編集前の値を保持する
    mut_holdCurrentInfo: (state) => {
      let _pre = state.st_editTemporaryInfo;
      let _inp = state.st_inputInfo;

      _pre.facility_code = _inp.facility_code;
      _pre.url = _inp.url;
      _pre.year = _inp.year;
      _pre.month = _inp.month;
      _pre.date = _inp.date;
      _pre.timezone = _inp.timezone;
      _pre.timezone_disp = _inp.timezone_disp;
      _pre.patient_name = _inp.patient_name;
      _pre.floor = _inp.floor;
      _pre.floor_text = _inp.floor_text;
      _pre.room = _inp.room;
      _pre.room_text = _inp.room_text;
      _pre.visitor_count = _inp.visitor_count;
      _pre.visitor = _inp.visitor;
      _pre.reservation_id = _inp.reservation_id;
      _pre.reservation_number = _inp.reservation_number;
      _pre.reservation_tel = _inp.reservation_tel;
      _pre.reservation_serial = _inp.reservation_serial;
    },

    // カレンダーの月エリアの内容を作る
    mut_createMonthAreaItem: (state) => {
      // 画面内で保持するリストをリセット
      state.st_monthItem = []; // 年月配列
      state.st_calendarItem = []; // カレンダーの内容
      state.st_timezoneItem = []; // 画面に表示する時間帯リスト

      let _now = new Date();
      // 月の配列に現在の月を追加
      state.st_monthItem.push({
        year: _now.getFullYear(),
        // month: _now.getMonth() + 1,
        month: _now.getMonth(),
        disp: true,
      });

      // 予約可能範囲
      let _range = state.st_facilityInfo["reservable_range"];
      // 本日と予約可能範囲の月の差
      let _tmpDay = new Date();
      _tmpDay.setDate(_tmpDay.getDate() + _range);
      let _diffMonth =
        (_tmpDay.getFullYear() - _now.getFullYear()) * 12 +
        (_tmpDay.getMonth() - _now.getMonth());

      // 年を表示するかどうか用
      let _year_standard = _now.getFullYear();
      // 月配列を作る
      for (let i = 0; i < _diffMonth; i++) {
        // ひと月加算
        _now.setDate(1); //いったん日付を1にする
        _now.setMonth(_now.getMonth() + 1);
        // 年が変わったかどうか（年が変われば年を表示するため）
        let _flg = false;
        if (_year_standard != _now.getFullYear()) {
          _flg = true;
        }
        _year_standard = _now.getFullYear();
        state.st_monthItem.push({
          year: _now.getFullYear(),
          // month: _now.getMonth() + 1,
          month: _now.getMonth(),
          disp: _flg,
        });
      }

      // // 年を表示するかどうか用
      // let _year_standard = _now.getFullYear();
      // // 月配列を作る
      // for (let i = 0; i < 6; i++) {
      //   // ひと月加算
      //   _now.setMonth(_now.getMonth() + 1);
      //   // 年が変わったかどうか（年が変われば年を表示するため）
      //   let _flg = false;
      //   if (_year_standard != _now.getFullYear()) {
      //     _flg = true;
      //   }
      //   _year_standard = _now.getFullYear();
      //   state.st_monthItem.push({
      //     year: _now.getFullYear(),
      //     // month: _now.getMonth() + 1,
      //     month: _now.getMonth(),
      //     disp: _flg,
      //   });
      // }
    },
    // カレンダー作る
    mut_generateCalendar: (state) => {
      // 予約可能範囲
      let _range = state.st_facilityInfo["reservable_range"];

      // 月の最初の日（4月の場合4/1）
      let _firstday = new Date(
        state.st_inputInfo.year,
        state.st_inputInfo.month,
        1
      );
      // 月の最後の日（4月の場合4/30）
      let _lastday = new Date(
        state.st_inputInfo.year,
        state.st_inputInfo.month + 1,
        0
      );

      // 月初週の日曜日を算出する
      // 月初の曜日
      let _youbi = _firstday.getDay();
      // 月初の曜日とその週の日曜日との日にち差
      let _diff = Math.abs(0 - _youbi);
      // カレンダーの最初の日曜日
      let _firstSunday = new Date(
        _firstday.setDate(_firstday.getDate() - _diff)
      );

      // 月末週の土曜日を算出する
      _youbi = _lastday.getDay();
      // 月末の曜日とその週の土曜日との日にち差
      _diff = Math.abs(6 - _youbi);
      // カレンダーの最後の土曜日
      let _lastSaturday = new Date(
        _lastday.setDate(_lastday.getDate() + _diff)
      );

      // カレンダー日数
      let _loopCnt = (_lastSaturday - _firstSunday) / 86400000; // 86400000ミリ秒=1日（1日ずつループ処理する）
      let _ary = [];
      _ary.push(_tmpCalendarMonth);

      let _ary_detail = [];
      let _tmpDay = new Date(_firstSunday);
      let _today = new Date();
      _today.setDate(_today.getDate() - 1);
      // 予約可能範囲日
      let _rangeDay = new Date(
        _today.getFullYear(),
        _today.getMonth(),
        _today.getDate() + _range + 1
      );
      for (let j = 1; j < _loopCnt + 2; j++) {
        let _able = true;
        // // 土日は選択不可にしておく
        // let _able = true;
        // if (j % 7 == 0 || (j - 1) % 7 == 0) {
        //   _able = false;
        // }
        // カレンダーマスタ情報（0なら予約可、9なら不可）
        var _tmpDayLocal = _tmpDay
          .toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replaceAll("/", "");
        const _filter = state.st_calendardayInfo.filter((elem) =>
          elem.calendar_day.includes(_tmpDayLocal)
        );
        if (_filter.length > 0) {
          if (_filter[0].reservable == 1) {
            _able = true;
          } else {
            _able = false;
          }
        }
        // 昨日以前は選択不可にしておく
        if (_today > _tmpDay) {
          _able = false;
        }
        // 月が異なる場合は選択不可にしておく
        if (state.st_inputInfo.month != _tmpDay.getMonth()) {
          _able = false;
        }
        // 予約可能範囲より先は選択不可にしておく
        if (_tmpDay > _rangeDay) {
          _able = false;
        }

        // カレンダーリストに追加
        _ary_detail.push({
          year: _tmpDay.getFullYear(),
          month: _tmpDay.getMonth(),
          date: _tmpDay.getDate(),
          able: _able,
        });

        // 次のループ用
        _tmpDay.setDate(_tmpDay.getDate() + 1);

        if (j % 7 == 0) {
          _ary.push(_ary_detail);
          _ary_detail = [];
        }
      }
      state.st_calendarItem = _ary;
    },
    // 予約情報を入力中変数にセットする
    mut_setInputInfoFromReservationInfo: (state, val) => {
      let _storeItems = state.st_inputInfo;
      _storeItems.facility_code = val.reservation_info.facility_code;
      _storeItems.year = val.reservation_info.reserve_year;
      _storeItems.month = val.reservation_info.reserve_month;
      _storeItems.date = val.reservation_info.reserve_day;
      _storeItems.timezone = val.reservation_info.timezone_code;
      _storeItems.timezone_disp = val.reservation_info.timezone_disp;
      _storeItems.patient_name = val.reservation_info.patient_name;
      _storeItems.floor = val.reservation_info.floor_num;
      _storeItems.floor_text =
        val.reservation_info.floor_num == _hensuu.selectAnswerUnknownValue
          ? _hensuu.selectAnswerUnknownText
          : String(val.reservation_info.floor_num) + "階";
      _storeItems.room = val.reservation_info.room_num;
      _storeItems.room_text =
        val.reservation_info.room_num == _hensuu.selectAnswerUnknownValue
          ? _hensuu.selectAnswerUnknownText
          : val.reservation_info.room_num;
      _storeItems.visitor_count = val.visitor_info.length;
      _storeItems.visitor = [];
      let _tmpAry = [];
      val.visitor_info.forEach((elem) => {
        let _tmpElem = {
          name: elem.name,
          contact: elem.contact,
          mailaddress: elem.mailaddress,
          relation_code: elem.relation_code,
          relation_name: elem.relation_name,
          relation_detail_code_btn: elem.relation_detail_code_btn,
          relation_detail_code_rdo: elem.relation_detail_code_rdo,
          relation_detail_name: elem.relation_detail_name,
          relation_detail_input: elem.relation_detail_input,
        };
        _tmpAry.push(_tmpElem);
      });
      _storeItems.visitor = _tmpAry;
      _storeItems.reservation_id = val.reservation_info.reserve_id;
      _storeItems.reservation_serial = val.reservation_info.reservation_serial;

      // 予約変更前の場合は、予約変更前変数にもセットする
      if (val.pre_edit) {
        let _preItems = state.st_preEditInfo;
        _preItems.facility_code = val.reservation_info.facility_code;
        _preItems.year = val.reservation_info.reserve_year;
        _preItems.month = val.reservation_info.reserve_month;
        _preItems.date = val.reservation_info.reserve_day;
        _preItems.timezone = val.reservation_info.timezone_code;
        _preItems.timezone_disp = val.reservation_info.timezone_disp;
        _preItems.patient_name = val.reservation_info.patient_name;
        _preItems.floor = val.reservation_info.floor_num;
        _preItems.floor_text =
          val.reservation_info.floor_num == _hensuu.selectAnswerUnknownValue
            ? _hensuu.selectAnswerUnknownText
            : String(val.reservation_info.floor_num) + "階";
        _preItems.room = val.reservation_info.room_num;
        _preItems.room_text =
          val.reservation_info.room_num == _hensuu.selectAnswerUnknownValue
            ? _hensuu.selectAnswerUnknownText
            : val.reservation_info.room_num;
        _preItems.visitor_count = val.visitor_info.length;
        _preItems.visitor = [];
        let _tmpAryPre = [];
        val.visitor_info.forEach((elem) => {
          let _tmpElemPre = {
            name: elem.name,
            contact: elem.contact,
            mailaddress: elem.mailaddress,
            relation_code: elem.relation_code,
            relation_name: elem.relation_name,
            relation_detail_code_btn: elem.relation_detail_code_btn,
            relation_detail_code_rdo: elem.relation_detail_code_rdo,
            relation_detail_name: elem.relation_detail_name,
            relation_detail_input: elem.relation_detail_input,
          };
          _tmpAryPre.push(_tmpElemPre);
        });
        _preItems.visitor = _tmpAryPre;
        _preItems.reservation_id = val.reservation_info.reserve_id;
        _preItems.reservation_serial = val.reservation_info.reservation_serial;
      }
    },
    // uikitのモーダルをなんとか閉じる
    mut_closeUikitModal: (state, val) => {
      /* eslint-disable no-unused-vars */
      state;
      /* eslint-enable no-unused-vars */
      let _elem = document.getElementsByName(val);
      _elem.forEach((el) => {
        UIkit.modal("#" + el.id).hide();
        // el.classList.remove("uk-open");
      });
    },
    // 時間帯に予約数をセットする
    mut_setReserveCount: (state) => {
      var _tmpDay = new Date(
        state.st_inputInfo.year,
        state.st_inputInfo.month,
        state.st_inputInfo.date
      );
      // 選択した日付をローカル時間にする
      var _tmpDayLocal = _tmpDay
        .toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "");

      // 予約上限数標準 or 特別判定
      let _limit_setting = 0; // 標準
      const _limit_set = state.st_calendardayInfo.filter(
        (calday) => calday.calendar_day === _tmpDayLocal
      );
      if (_limit_set.length > 0) {
        _limit_setting = _limit_set[0].reservation_limit_setting;
      }

      // 予約数情報を現在選択中の日付に絞る
      const _filter = state.st_reserveCountInfo.filter(
        (rsv) => rsv.reserve_ymd_str === _tmpDayLocal
      );
      // 時間帯リストに予約数をセットする
      state.st_timezoneItem.forEach((tim) => {
        tim.reservable = true;
        // 予約数情報をさらに時間帯で絞る
        const _extract = _filter.filter(
          (fil) => fil.timezone_code === tim.timezone_code
        );
        if (_extract.length > 0) {
          // 予約上限数の設定（標準 or 特別）
          switch (_limit_setting) {
            // 標準の場合（平日の予約上限数）
            case 0:
              if (_extract[0].reserve_cnt >= tim.reservation_limit) {
                tim.reservable = false;
              }
              break;
            // 特別の場合（土日の予約上限数）
            case 1:
              if (_extract[0].reserve_cnt >= tim.specific_reservation_limit) {
                tim.reservable = false;
              }
              break;
          }
        }
      });
    },
    // 時間帯情報を作成する（選択した日付が表示期間に該当するもののみ）
    mut_createTimezoneForDisplay: (state) => {
      state.st_timezoneItem = [];

      // 選択した日付
      var _tmpDay = new Date(
        state.st_inputInfo.year,
        state.st_inputInfo.month,
        state.st_inputInfo.date
      );

      // 選択した日付をローカル時間にする
      var _tmpDayLocal = _tmpDay
        .toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "");
      // 時間帯の表示期間内のもののみ表示する
      state.st_timezoneInfo.forEach((item) => {
        // 現在時刻 > 時間帯の場合は選択不可とする
        item.timeover = false;
        let _limit = new Date(
          state.st_inputInfo.year,
          state.st_inputInfo.month,
          state.st_inputInfo.date,
          item.timezone_display_to.split(":")[0],
          item.timezone_display_to.split(":")[1]
        );
        if (new Date() > _limit) {
          item.timeover = true;
        }
        // 時間帯の表示期間内のもの
        if (
          item.target_period_from <= _tmpDayLocal &&
          _tmpDayLocal <= item.target_period_to
        ) {
          state.st_timezoneItem.push(item);
        }
      });
    },

    // 予約編集前の値に戻す（面会日）
    mut_undoEditReserveYmd: (state) => {
      let _pre = state.st_preEditInfo;
      let _inp = state.st_inputInfo;

      _inp.year = _pre.year;
      _inp.month = _pre.month;
      _inp.date = _pre.date;
    },
    // 予約編集前の値に戻す（時間帯）
    mut_undoEditTimezone: (state) => {
      let _pre = state.st_preEditInfo;
      let _inp = state.st_inputInfo;

      _inp.timezone = _pre.timezone;
      _inp.timezone_disp = _pre.timezone_disp;
    },

    // 予約編集前の値に戻す（患者氏名）
    mut_undoEditPatientName: (state) => {
      let _pre = state.st_preEditInfo;
      let _inp = state.st_inputInfo;

      _inp.patient_name = _pre.patient_name;
    },

    // 予約編集前の値に戻す（階、病室）
    mut_undoEditHproom: (state) => {
      let _pre = state.st_preEditInfo;
      let _inp = state.st_inputInfo;

      _inp.floor = _pre.floor;
      _inp.floor_text = _pre.floor_text;
      _inp.room = _pre.room;
      _inp.room_text = _pre.room_text;
    },

    // 予約編集前の値に戻す（訪問者）
    mut_undoEditVisitor: (state) => {
      let _pre = state.st_preEditInfo;
      let _inp = state.st_inputInfo;

      let _tmpAryInp = [];
      _pre.visitor.forEach((elem) => {
        let _tmpElemInp = {
          name: elem.name,
          contact: elem.contact,
          mailaddress: elem.mailaddress,
          relation_code: elem.relation_code,
          relation_name: elem.relation_name,
          relation_detail_code_btn: elem.relation_detail_code_btn,
          relation_detail_code_rdo: elem.relation_detail_code_rdo,
          relation_detail_name: elem.relation_detail_name,
          relation_detail_input: elem.relation_detail_input,
        };
        _tmpAryInp.push(_tmpElemInp);
      });
      _inp.visitor = _tmpAryInp;
      _inp.visitor_count = _pre.visitor_count;
    },
  },
  actions: {
    // サーバー処理開始
    act_startServerProcess: (context) => {
      context.commit("mut_setIsServerProcess", true);
    },
    // サーバー処理終了
    act_endServerProcess: (context) => {
      window.setTimeout(() => {
        context.commit("mut_setIsServerProcess", false);
      }, 50);
    },
    // 処理終了通知を表示する（正常終了）
    act_notificationEndProcSuccess: (context) => {
      context.commit("mut_notificationEndProc", true);
    },
    // 処理終了通知を表示する（異常終了）
    act_notificationEndProcError: (context) => {
      context.commit("mut_notificationEndProc", false);
    },
    // 任意のメッセージを通知する
    act_notificationAny: (context, val) => {
      context.commit("mut_notificationAny", val);
    },
    // 通知を全て閉じる
    act_closeAllNotification: (context) => {
      context.commit("mut_closeAllNotification");
    },
    // 施設コード保持
    act_holdFacilityCode: (context, val) => {
      context.commit("mut_holdFacilityCode", val);
    },
    // 施設情報保持
    act_holdFacilityInfo: (context, val) => {
      context.commit("mut_holdFacilityInfo", val);
    },
    // 注意事項保持
    act_holdConsentInfo: (context, val) => {
      context.commit("mut_holdConsentInfo", val);
    },
    // カレンダー情報保持
    act_holdCalendardayInfo: (context, val) => {
      context.commit("mut_holdCalendardayInfo", val);
    },
    // 時間帯情報保持
    act_holdTimezoneInfo: (context, val) => {
      context.commit("mut_holdTimezoneInfo", val);
    },
    // 予約数情報保持
    act_holdReserveCountInfo: (context, val) => {
      context.commit("mut_holdReserveCountInfo", val);
    },
    // 階情報保持
    act_holdFloorInfo: (context, val) => {
      context.commit("mut_holdFloorInfo", val);
    },
    // 病室情報保持
    act_holdHproomInfo: (context, val) => {
      context.commit("mut_holdHproomInfo", val);
    },
    // 訪問人数上限保持
    act_setVisitorLimit: (context, val) => {
      context.commit("mut_holdVisitorLimit", val);
    },
    // 患者さまとのご関係情報保持
    act_setRelationInfo: (context, val) => {
      context.commit("mut_holdRelationInfo", val);
    },
    // 患者さまとのご関係詳細情報保持
    act_setHoldRelationDetailInfo: (context, val) => {
      context.commit("mut_holdRelationDetailInfo", val);
    },
    // 予約情報保持
    act_holdReservationInfo: (context, val) => {
      context.commit("mut_holdReservationInfo", val);
    },
    // 画面ヘッダーの施設名をセットする
    act_setHeaderFacilityName: (context, val) => {
      context.commit("mut_setDispHeaderFacilityName", val);
    },
    // 入力中の値リセット
    act_clearInputInfo: (context) => {
      context.commit("mut_clearInputInfo");
    },
    // 子画面等の表示をオフ
    act_dispNoneChildComponent: (context) => {
      context.commit("mut_dispNoneChildComponent");
    },
    // 予約編集前の値を保持する
    act_holdCurrentInfo: (context) => {
      context.commit("mut_holdCurrentInfo");
    },
    // カレンダー作る
    act_generateCalendar: (context) => {
      context.commit("mut_generateCalendar");
    },
    // カレンダーの月エリアの内容作る
    act_createMonthAreaItem: (context) => {
      context.commit("mut_createMonthAreaItem");
    },
    // 予約情報を入力中変数にセットする
    act_setInputInfoFromReservationInfo: (context, val) => {
      context.commit("mut_setInputInfoFromReservationInfo", val);
    },
    // uikitのモーダルをなんとか閉じる
    act_closeUikitModal: (context, val) => {
      context.commit("mut_closeUikitModal", val);
    },
    // 時間帯情報を作成する（選択した日付が表示期間に該当するもののみ）
    act_createTimezoneForDisplay: (context) => {
      context.commit("mut_createTimezoneForDisplay");
    },
    // 時間帯に予約数をセットする
    act_setReserveCount: (context) => {
      context.commit("mut_setReserveCount");
    },
    // 予約編集前の値に戻す（面会日）
    act_undoEditReserveYmd: (context) => {
      context.commit("mut_undoEditReserveYmd");
    },
    // 予約編集前の値に戻す（時間帯）
    act_undoEditTimezone: (context) => {
      context.commit("mut_undoEditTimezone");
    },
    // 予約編集前の値に戻す（患者氏名）
    act_undoEditPatientName: (context) => {
      context.commit("mut_undoEditPatientName");
    },
    // 予約編集前の値に戻す（階、病室）
    act_undoEditHproom: (context) => {
      context.commit("mut_undoEditHproom");
    },
    // 予約編集前の値に戻す（訪問者）
    act_undoEditVisitor: (context) => {
      context.commit("mut_undoEditVisitor");
    },
  },
});
